import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Legitimation = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Legitimation bei Kreditverträgen – was ist das?" showCalc={false} />
            <Article>
                <p>
                    Deine{" "}
                    <Link
                        to="/artikel/haushaltsrechnung-schritt-fuer-schritt/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Haushaltsrechnung
                    </Link>{" "}
                    hast du sauber durchgeführt und auch die Menge an{" "}
                    <Link to="/artikel/finanzierung-ohne-eigenkapital/" target="_blank" rel="noreferrer noopener">
                        Eigenkapital
                    </Link>
                    , auf die du zurückgreifen kannst, entspricht den{" "}
                    <Link to="/artikel/kreditregeln/" target="_blank" rel="noreferrer noopener">
                        neuen Kreditregeln
                    </Link>
                    . Vor dem Erstgespräch hast du dich selbstständig mit einem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    über die Konditionen informiert, die bei deinen Mitteln erwartbar sind, und dich damit in die
                    optimale Ausgangssituation für das Verhandlungsgespräch gebracht. Der Finanzierung deines neuen
                    Zuhauses steht also nichts mehr im Weg. Doch auch bei der Unterzeichnung deines Kreditvertrags gibt
                    es einiges zu beachten – etwa die Legitimation. Was darunter zu verstehen ist, erklären wir dir im
                    folgenden Artikel.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist eine Legitimation?</h2>
                <p>
                    Geht es um die Unterzeichnung von Verträgen und anderen wichtigen Dokumenten, dann meint
                    ‘Legitimation’ die offizielle Überprüfung der Identität derjenigen, die das jeweilige Schriftstück
                    unterschreiben. Gerade bei Bankgeschäften ist eine Legitimation meist unerlässlich - sei es bei der
                    Eröffnung eines Kontos, eines Wertpapierdepots oder eben bei der Unterzeichnung eines
                    Kreditvertrags.
                </p>
                <hr />

                <h2>Wie erfolgt die Legitimation?</h2>
                <p>
                    Die Legitimation erfolgt durch Dokumente, die deine Identität zweifelsfrei feststellen. Sie müssen
                    folglich amtlich beglaubigt sein. Diese Kriterien erfüllen ausschließlich dein Reisepass sowie dein
                    Personalausweis. Sie enthalten sämtliche für eine Legitimation benötigten Daten. Darunter fallen
                    Vor- und Nachname, Geburtsdatum, Geburtsort, aktuelle Anschrift sowie deine Staatsangehörigkeit.
                    Natürlich muss dein Reisepass oder Personalausweis zum Zeitpunkt der Vertragsunterzeichnung noch
                    gültig sein. Meist unzulässig sind dagegen andere Dokumente wie Führerschein, Meldezettel oder
                    Betriebsausweis.
                </p>
                <hr />

                <h2>Warum werden Dokumente legitimiert?</h2>
                <p>
                    Eine Legitimation stellt die Echtheit einer Unterschrift sicher und bewirkt damit, dass Verträge und
                    andere Dokumente rechtswirksam werden. So prüft auch das Kreditinstitut bei der Aufnahme eines{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>
                    , ob die Unterschrift auf dem Vetrag mit der auf dem Reisepass bzw. Personalausweis (den sogenannten
                    Legitimationspapieren) übereinstimmt. Die Echtheit einer Unterschrift bedeutet, dass die
                    Unterzeichnende die Rechte und Pflichten, die ihr im Vertrag zugeschrieben werden, zur Gänze
                    akzeptiert und verantwortet.
                </p>
                <hr />

                <h2>Ein wichtiger Hinweis zum Schluss</h2>
                <p>
                    Eine Sache solltest du beim Abschluss eines Vertrags in jedem Fall bedeken: In Österreich besteht
                    kein Rechtsschutz für den guten Glauben an die Authentizität einer Unterschrift. Verzichten die
                    Vertragspartner:innen auf einen Legitimation und die Unterschrift erweist sich als unecht, so ist
                    das jeweilige Dokument nicht rechtswirksam. Kreditinstitute sind allerdings bei der Eröffnung von
                    Konten, Depots sowie bei Tafelgeschäften gesetzlich dazu verpflichtet, eine Legitimation
                    durchzuführen.
                </p>
            </Article>

            <BreadcrumbList page={"legitimation"}></BreadcrumbList>
            <ArticleStructuredData page={"legitimation"} heading={"Legitimation bei Kreditverträgen – was ist das?"} />
        </Layout>
    );
};

export default Legitimation;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.legitimation", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
